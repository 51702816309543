<script setup lang="ts" generic="T">
import type { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { KsIcon, KsInput } from '@aschehoug/kloss'
import { faChevronDown, faSearch, faXmark } from '@fortawesome/pro-light-svg-icons'

const props = withDefaults(
  defineProps<{
    options: T[] | ReadonlyArray<T>
    noOptions?: string
    iconLeft?: IconDefinition
    placeholder: string
    closeOnSelect?: boolean
    size?: DropdownSize
    optionLabel?: (option: T) => string
    optionIcon?: (option: T) => IconDefinition
    searchKey?: (option: T) => string
    selectable?: (option: T) => boolean
    deselectable?: (option: T) => boolean
    optionId?: (option: T) => string
    reduce?: (option: T) => any
    variant: DropdownVariant
    searchable?: boolean
    multiple?: boolean
    resettable?: boolean
  }>(),
  {
    multiple: false,
    closeOnSelect: true,
    iconLeft: undefined,
    resettable: false,
    searchable: false,
    searchKey: undefined,
    variant: 'filled',
    size: 'small',
    noOptions: 'Ingen resultater',
    optionLabel: (option: T) => JSON.stringify(option),
    optionId: (option: T) => JSON.stringify(option),
    optionIcon: undefined,
    reduce: (option: T) => option,
    selectable: () => true,
    deselectable: () => true,
  },
)

const emit = defineEmits<{
  (event: 'open'): void
  (event: 'close'): void
  (event: 'search', searchValue: string): void
  (event: 'select', option: T): void
  (event: 'reset'): void
}>()

const model = defineModel<T | T[] | undefined>({
  default: undefined,
})

const id = useId()
const open = ref<boolean>(false)
const selecting = ref<boolean>(false)
const filterValue = ref<string>('')
const searchValue = ref<string>('')
const list = ref()
const input = ref()
const searchInput = ref()
const dropdown = ref()

const displayValue = computed(() => {
  return (props.options.filter(isSelected).map(props.optionLabel).join(', ') || props.placeholder)
})

const lowerCaseSearchValue = computed(() => {
  return searchValue.value.toLocaleLowerCase()
})

const filteredOptions = computed(() => {
  if (!props.searchable || searchValue.value === '') {
    return props.options
  }

  return props.options.filter(option =>
    searchKey(option).includes(lowerCaseSearchValue.value),
  )
})

const hasSelection = computed(() => {
  return displayValue.value !== props.placeholder
})

const hasOptions = computed(() => {
  return filteredOptions.value.length > 0
})

const showReset = computed(() => {
  return props.resettable && hasSelection.value && hasOptions.value
})

function searchKey(option: T): string {
  if (!props.searchKey) {
    return JSON.stringify(option).toLocaleLowerCase()
  }

  return props.searchKey(option).toLocaleLowerCase()
}

function equals(option1: T | undefined, option2: T | undefined): boolean {
  if (!option1 || !option2) {
    return false
  }

  return props.optionId(option1) === props.optionId(option2)
}

function isSelected(option: T): boolean {
  const optionReduced = props.reduce(option)

  if (Array.isArray(model.value)) {
    return model.value.some(opt => equals(opt, optionReduced))
  }
  else {
    return equals(model.value, optionReduced)
  }
}

async function onSelect(option: T) {
  if (!props.selectable(option)) {
    return
  }

  const optionReduced = props.reduce(option)
  let selection = null

  if (props.multiple) {
    const valueArray = Array.isArray(model.value) ? model.value : []
    const isSelected = valueArray.some((opt: T) => equals(opt, optionReduced))

    if (isSelected && props.deselectable(option)) {
      selection = valueArray.filter(opt => !equals(opt, optionReduced))
    }
    else {
      selection = valueArray.concat(optionReduced)
    }
  }
  else {
    const isSelected = equals(model.value as T | undefined, optionReduced)

    if (isSelected && props.deselectable(option)) {
      selection = null
    }
    else {
      selection = optionReduced
    }
  }

  await nextTick()
  model.value = selection
  emit('select', option)

  selecting.value = true
  if (props.closeOnSelect) {
    filterValue.value = ''
    input.value.el.focus()
  }
}

function onOpen() {
  open.value = true
  emit('open')
}

function onClose() {
  open.value = false
  emit('close')
}

function onToggle() {
  if (open.value) {
    onClose()
  }
  else {
    onOpen()
  }
}

function onInput() {
  emit('search', searchValue.value)
}

function onReset() {
  model.value = props.multiple ? [] : undefined
  emit('reset')
}

function onEscape() {
  onClose()
  input.value.el.focus()
}

function onFocusin(event: FocusEvent) {
  const target = event.target as HTMLElement
  const relatedTarget = event.relatedTarget as HTMLElement
  const inputEl = input.value?.el
  const listEl = list.value

  if (target === inputEl && !listEl?.contains(relatedTarget)) {
    onOpen()
  }

  if (selecting.value && props.closeOnSelect) {
    selecting.value = false
    onClose()
  }
}

function onFocusout(event: FocusEvent) {
  const relatedTarget = event.relatedTarget as HTMLElement | null
  const rootEl = dropdown.value

  if (!rootEl?.contains(relatedTarget)) {
    onClose()
  }
}

function onUpDown(event: KeyboardEvent) {
  event.preventDefault()

  const key = event.key
  const target = event.target as HTMLElement
  const isUp = key === 'ArrowUp'
  let focusTarget: HTMLElement | null = null

  const inputEl = input.value?.el as HTMLElement | null
  const listEl = list.value as HTMLElement | null
  const searchInputEl = searchInput.value?.el as HTMLElement | null

  if (!inputEl || !listEl) {
    return
  }

  if (target === inputEl) {
    if (props.searchable && !isUp && searchInputEl) {
      // Move focus to the search input field on ArrowDown
      focusTarget = searchInputEl
    }
    else {
      // Move focus to the first or last option
      focusTarget = isUp ? (listEl.lastElementChild as HTMLElement | null) : (listEl.firstElementChild as HTMLElement | null)
    }
  }
  else if (target === searchInputEl) {
    if (isUp) {
      // Move focus back to the main input field on ArrowUp
      focusTarget = inputEl
    }
    else {
      // Move focus to the first option on ArrowDown
      focusTarget = (listEl.lastElementChild?.nextElementSibling as HTMLElement | null)
    }
  }
  else if (listEl.contains(target)) {
    // If focus is on an option, move to previous or next option
    focusTarget = isUp ? (target.previousElementSibling as HTMLElement | null) : (target.nextElementSibling as HTMLElement | null)
  }

  // Ensure the dropdown is open
  open.value = true

  // If no valid focus target, default to input element
  focusTarget = focusTarget || inputEl

  // Move focus to the target element
  focusTarget.focus()
}
</script>

<template>
  <div
    ref="dropdown" class="relative flex flex-col gap-xs transition-all duration-300 ease-in" role="combobox" tabindex="0" :aria-controls="`dropdown-${id}`" :aria-expanded="open" @keydown.esc.prevent="onEscape" @keydown.up.down.prevent="onUpDown"
  >
    <div class="flex items-center rounded-lg text-small gap-2xs" :class="[`dropdown-${variant}`, `dropdown-${size}`]" @focusin="onFocusin" @focusout="onFocusout">
      <KsIcon v-if="iconLeft" :icon="iconLeft" class="absolute text-[currentColor] pointer-events-none" :class="size !== 'small' ? 'left-6' : 'left-4'" />
      <label :for="`dropdown-label-${id}`" class="sr-only">{{ placeholder }}</label>
      <KsInput
        :id="`dropdown-label-${id}`"
        ref="input"
        v-model="filterValue"
        readonly
        shape="normal"
        type="text"
        :aria-labelledby="`dropdown-label-${id}`"
        :placeholder="displayValue"
        @mousedown="onToggle"
        @keydown.enter.self="onOpen"
      />
      <KsIcon
        :icon="faChevronDown"
        :scale="size !== 'small' ? 1 : 0.7"
        class="absolute right-4 top-6.5 text-blurple-500 pointer-events-none ease-out duration-500 transition-all"
        :class="!open ? 'rotate-360' : 'rotate-180'"
      />
    </div>
    <div class="dropdown-list" :class="!open ? 'transform invisible opacity-0 -translate-y-6' : 'transform visible opacity-100 translate-y-0'">
      <ul :id="`dropdown-${id}`" ref="list" role="listbox" :hidden="!open" tabindex="-1">
        <div v-if="searchable">
          <KsIcon :icon="faSearch" />
          <KsInput ref="searchInput" v-model="searchValue" type="search" role="searchbox" shape="normal" autocomplete="off" placeholder="Search..." aria-description="Søkeresultatene vil vises nedenfor" @input="onInput" />
        </div>
        <li
          v-for="(option, index) in filteredOptions"
          :key="index"
          role="option"
          :aria-posinset="index + 1"
          :aria-setsize="filteredOptions.length"
          :aria-selected="isSelected(option)"
          :aria-disabled="!props.selectable(option)"
          tabindex="0"
          @click="onSelect(option)"
          @keydown.enter.space.prevent="onSelect(option)"
        >
          <KsIcon v-if="optionIcon" :icon="optionIcon(option)" />
          {{ optionLabel(option) }}
          <!-- <KsIcon v-if="isSelected(option)" :icon="faCheck" class="absolute top-4 right-6" /> -->
        </li>
        <li v-if="!hasOptions" v-text="noOptions" />
        <Button v-if="showReset" class="w-full min-w-max rounded-lg" :icon="faXmark" icon-position="right" aria-label="Nullstill valg" title="Nullstill valg" @click="onReset">
          Remove all
        </Button>
      </ul>
    </div>
  </div>
</template>

<style scoped lang='postcss'>
div {
  &[aria-expanded='true'] {
    > .dropdown-outlined {
      @apply bg-blurple-100 outline-blurple-100;
    }
  }

  &.dropdown-filled {
    @apply bg-blurple-100 outline outline-1 outline-blurple-100 active:bg-blurple-200 active:outline-blurple-200 hover:bg-blurple-50 hover:border-blurple-50;
  }

  &.dropdown-outlined {
    @apply bg-white outline outline-1 outline-blurple-500 active:bg-blurple-50 active:outline-blurple-300 hover:outline-2 hover:outline-blurple-300;
  }

  &.dropdown-small {
    @apply py-xs px-s;
  }

  &.dropdown-medium {
    @apply h-15 py-s px-m;
  }

  input[type='text'] {
    @apply text-blurple-500 items-center h-auto placeholder:text-blurple-500 cursor-pointer bg-transparent p-0 pl-l pr-s shadow-none !important;
  }

  &.dropdown-list {
    @apply bg-white rounded-lg absolute top-full z-50 min-w-full mt-xs transition-all ease-in-out duration-500;

    > ul {
      @apply w-full rounded-lg border border-blurple-300 overscroll-contain overflow-y-auto list-none max-h-92;

      > div {
        @apply relative flex items-center h-15 px-m border-b border-blurple-300;

        > svg {
          @apply absolute left-6 top-5 w-5 text-blurple-300 pointer-events-none;
        }
      }

      input[type='search'] {
        @apply text-blurple-500 placeholder:text-blurple-500 rounded-t-lg border-b border-b-blurple-300 cursor-pointer bg-transparent p-0 px-l !important;
      }

      > li {
        @apply relative flex items-center py-s px-m gap-s text-sm font-normal sm:text-base cursor-pointer text-blurple-500 hover:bg-blurple-50 active:bg-blurple-200;
      }

      > button {
        @apply justify-between rounded-b-lg rounded-t-none px-m text-base py-s;
      }
    }
  }
}
</style>
